<script setup>

import {onMounted, onUnmounted, ref} from "vue";
import AR from "../js/playcanvasAR.js"
import {useStore} from "vuex";
import audioControl from "@/js/audioControl";

const store = useStore();

let animation = ref(null);
let initial = ref(null);

let initialVideoEnabled = ref(true);
let animationEnabled = ref(false);
let reminderMessageEnabled = ref(false);
let cokeLogoEnabled = ref(false);
let loaded = true;

let reminderTimeout;
let cokeLogoTimeout;

onMounted(async () => {
  AR.app.on('xr:imagefound', scanStarted)
  AR.app.on('xr:imageupdated', scanProgressing)
  AR.app.on('xr:imagelost', imageLost)
  initialVideoEnabled.value = true;
  animationEnabled.value = false;
  //initial.value.load();
  cokeLogoTimeout = setTimeout(function () {
    cokeLogoEnabled.value = true;
  }, 1000)
  reminderTimeout = setTimeout(function () {
    reminderMessageEnabled.value = true;
  }, 2000)
});
onUnmounted(async () => {
  AR.app.off('xr:imagefound', scanStarted)
  AR.app.off('xr:imageupdated', scanProgressing)
  AR.app.off('xr:imagelost', imageLost)
  initialVideoEnabled.value = false;
  animationEnabled.value = false;
  //loaded = false;
});

const scanStarted = function () {
  //when we have a video for this, show the video
  if (loaded && animationEnabled.value)
  {
    animation.value.play();
    initialVideoEnabled.value = false;
    clearTimeout(reminderTimeout);
    reminderMessageEnabled.value = false;
    clearTimeout(cokeLogoTimeout);
    cokeLogoEnabled.value = false;
  }

}
const scanProgressing = function () {
  reminderMessageEnabled.value = false;
  cokeLogoEnabled.value = false;
  /*
  if (loaded && animationEnabled.value && !animation.value.isPlaying && !store.getters.getScanned)
  {
    animation.value.play();
    initialVideoEnabled.value = false;
  }
  */
}
const imageLost = function () {
  animation.value.currentTime = 0;
  animation.value.pause();
  cokeLogoEnabled.value = true;
  reminderMessageEnabled.value = true;
  //scanProgress.value = 0;
}

const initialAnimationEnded = function () {
  animationEnabled.value = true;
}
const scanAnimationEnded = function () {
  AR.app.showARContent = true;
  store.commit("setScanned", true)
  AR.app.fire("play-sound", SoundType.MUSIC, "background")
  setTimeout(function (){
    AR.app.fire("play-sound", SoundType.EFFECT, "roar")
  }, 1500)
  AR.app.fire("start-video")
  animationEnabled.value = false;
  initialVideoEnabled.value = false;
}
/*
let initialAnimationLoaded = false;
let scanAnimationLoaded = false;
const initialAnimationReady = function () {
  if (!initialAnimationLoaded)
  {
    initialAnimationLoaded = true;
    if (scanAnimationLoaded)
    {
      if (initial.value)
      {
        initial.value.play();
        loaded = true;
      }
    }
  }

}
const scanAnimationReady = function () {
  if (!scanAnimationLoaded)
  {
    scanAnimationLoaded = true;
    if (initialAnimationLoaded)
    {
      if (initial.value)
      {
        initial.value.play();
        loaded = true;
      }
    }
  }


}
 */

</script>

<template>
  <div class="scanner-container">
    <transition name = "new-fade">
      <img src="../../public/assets/imgs/reminder-message.png" class="reminder-message" v-if="reminderMessageEnabled">
    </transition>
    <transition name = "new-fade">
      <img src="../../public/assets/imgs/coke-logo-scan.png" class="coke-logo-scan" v-if="cokeLogoEnabled">
    </transition>
    <video class="reticle-animation" ref="initial" autoplay muted playsinline crossorigin="anonymous" @ended="initialAnimationEnded"  v-show="initialVideoEnabled">
      <source src="assets/vid/scanning_intro.webm">
      <source src="assets/vid/scanning_intro_hevc.mov">
    </video>
    <video class="reticle-animation" ref="animation" muted playsinline crossorigin="anonymous" v-show="animationEnabled" @ended="scanAnimationEnded">
      <source src="assets/vid/scanning_fill.webm">
      <source src="assets/vid/scanning_fill_hevc.mov">
    </video>
  </div>
</template>

<style scoped lang="scss">
@import "../sass/Settings";

.scanner-container {
  .reminder-message {
    position: absolute;
    left: 50%;
    top: 86%;
    transform: translate(-50%, -50%);
    width: vw(157);
    height: vw(39);
  }
  .coke-logo-scan {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: vw(172);
    height: vw(172);
  }
  .reticle-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: vw(214);
    height: vw(378);
  }
  .reticle-animation {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
  }
}
</style>
