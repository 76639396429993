///////////////////////////////////////////////////////////////////////////
/*
Name: 			permissions.js

Description: 	Gets the various permissions for the app including camera and gyro.

Usage:			- import permissions from "./permissions.js"
                - Call the method corresponding to the permission you wish to get. Its highly encouraged you await this.
*/
///////////////////////////////////////////////////////////////////////////

import { store } from "../main";
import { getQueryParam } from "../store/state.js";
import { isMobile, isTablet } from "mobile-device-detect";

const skipGyroCheck = () => getQueryParam("debugGyro") === "1";
const reactionTime = 250;

const permissions = {
  async gyroCheck() {
    //Motion Access Check
    if (!window.gyroCheck && !skipGyroCheck()) {
      store.commit("setMotionPermissionsRequested", true);
      try {
        await DeviceOrientationEvent.requestPermission().then(
          (permissionState) => {
            if (permissionState != "granted") {
              store.commit("setGyro", false);
              store.commit("setAppFailed", true);
            }
          }
        );
      } catch (err) {
        store.commit("setGyro", false);
        store.commit("setAppFailed", true);
      }
      store.commit("setMotionPermissionsRequested", false);
    }
  },
  async requestCameraPermissions() {
    store.commit("setCameraPermissionsRequested", true);
    let askTime = new Date().getTime();
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          //Let go of the stream so the AR can take it over once it initializes
          let tracks = stream.getTracks();
          tracks.forEach(function (track) {
            track.stop();
          });
        });
    } catch (err) {
      let denialTime = new Date().getTime();
      store.commit("setCameraPermissionsFailed", true);

      if (denialTime - askTime < reactionTime) {
        store.commit("setWasCameraAutoRejected", true);
        store.commit("setAppFailed", true);
      }
      //if (store.getters.getIsAndroid) {
        store.commit("setAppFailed", true);
      //}
      return Promise.reject(new Error("Camera Permission Denied"));
    }
    store.commit("setCameraPermissionsRequested", false);
  },
  async micPermissions() {
    var permissionSuccess = true;

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (err) {
      permissionSuccess = false;
    }

    if (!permissionSuccess) {
      store.commit("setCameraPermissionsFailed", true);
      //if (store.getters.getIsAndroid) {
        store.commit("setAppFailed", true);
      //}
      return Promise.reject(new Error("Permission Denied 3"));
    }
  },
  checkOrientation() {
    if (isTablet || isMobile) {
      if (window.orientation === 0) {
        store.commit("setIsRotated", false);
      } else {
        store.commit("setIsRotated", true);
        window.scrollTo(0, 0);
      }
    }
  },
};

window.gyroCheck = false;
const motionListener = () => {
  window.gyroCheck = true;
  window.removeEventListener("devicemotion", motionListener);
};
window.addEventListener("devicemotion", motionListener);

window.addEventListener(
  "orientationchange",
  permissions.checkOrientation,
  false
);

export default permissions;
