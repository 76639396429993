<script setup>
import { onUnmounted, ref } from "vue";
import LegalFooter from "../components/modules/PopupLegalFooter.vue";
import Debug from "../components/debug_modules/DebugMenu.vue";
const timeout = ref(null);

onUnmounted(() => clearTimeout(timeout.value));
</script>

<template>
  <div class="landing-page absolute-fill">
    <div class="gradient"></div>
    <div class="coke-text-header">
      Jaguar! AR Cup Scan
    </div>
    <div class="coke-text-body">
      Unlock an immersive AR experience by scanning your Coca-Cola cup and capture the moment with a photo!
    </div>
    <div class = "coke-button-primary" @click.once="$emit('handle-continue')" id="ar_landing_lets_go">
      <div class = "text">Let's go</div>
    </div>
    <LegalFooter />
    <Debug />
  </div>
</template>

<style scoped lang='scss'>
@import '../sass/GlobalClasses';
@import '../sass/Settings';

.landing-page {
  background: url("../../public/assets/imgs/landing-background.png");
  background-size: cover;
  background-position: center;
  .coke-text-header {
    font-size: vw(24);
    position: absolute;
    left: vw(32);
    bottom: vw(188);
  }
  .coke-text-body {
    font-size: vw(14);
    position: absolute;
    left: vw(32);
    bottom: vw(123);
    width: vw(279);
  }
  .coke-button-primary {
    width: vw(237);
    height: vw(47);
    position: absolute;
    left: vw(32);
    bottom: vw(60);
  }
  .gradient {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) 44%, #000);
    width: 100%;
    height: vw(447);
    bottom: 0;
    left: 0;
    position: absolute;
  }
}

</style>
