<script setup>
  import { inject } from 'vue'
  import LegalFooter from "@/components/modules/PopupLegalFooter.vue";
  const translate = inject('translate')
</script>

<template>
  <div class="module-container fail-desktop absolute-fill">

    <img  src="../../../public/assets/imgs/logo-coke-white.png" class="logo-coke">

    <img  src="../../../public/assets/imgs/desktop-error-banner.png" class="landing-background">

    <div class="large-vertical-group">
      <div class="coke-text-header">Jaguar! AR Cup Scan</div>
      <div class="line">
      </div>
      <div class="coke-text-body">To enjoy this AR Experience, visit<br>Knott’s Berry Farm!</div>
    </div>

    <LegalFooter />
  </div>
</template>

<style scoped lang="scss">
  @import "../../sass/Settings";

  .fail-desktop
  {

    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    .landing-background {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: auto;
    }
    .logo-coke {
      position: absolute;
      top: vh(31, $design-height-desktop);
      left: vh(32, $design-height-desktop);
      width: vh(162, $design-height-desktop);
      height: vh(50, $design-height-desktop);
    }

    .large-vertical-group {
      display: flex;
      flex-direction: column;

      position: absolute;

      top: vh(300, $design-height-desktop);
      left: vh(250, $design-height-desktop);

      .coke-text-header {
        font-size: vh(52, $design-height-desktop);
        line-height: 1.07;
        letter-spacing: vh(0.56, $design-height-desktop);
      }
      .line {
        width: vh(56, $design-height-desktop);
        height: vh(3, $design-height-desktop);
        background-color: #FFFFFF;
        margin-top: vh(16, $design-height-desktop);
        margin-bottom: vh(21, $design-height-desktop);
      }
      .coke-text-body {
        font-size: vh(24, $design-height-desktop);
        line-height: 1.14;
        letter-spacing: vh(0.28, $design-height-desktop);
      }
    }

  }

  .module {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 0;
  }
</style>
