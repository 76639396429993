<script setup>
import {useStore} from "vuex";
import audioControl from "@/js/audioControl";

const store = useStore();
const close = function () {
  audioControl.restartAudio("positive");
  store.commit("setInstructionsClosed", true);
};
</script>

<template>
  <div>
    <div class="faded-background absolute-fill"></div>
    <div class="instructions-popup">
      <img src="../../public/assets/imgs/instructions-popup-banner.png" class="instructions-popup-banner">
      <div class="coke-text-header">Scan to Begin</div>
      <div class="coke-text-body">Scan a Coca-Cola cup to begin!</div>
      <div class="coke-button-primary" @click="close" id="ar_instructions_start"><div>Start</div></div>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../sass/Settings";

  .faded-background {
    opacity: 0.5;
    background-color: #000;;
  }
  .instructions-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: vw(319);
    height: vw(375);
    border-radius: vw(8);
    box-shadow: 0 vw(3) vw(10) 0 rgba(0, 0, 0, 0.1);
    background-color: #d00013;
    overflow: hidden;
    .instructions-popup-banner {
      width: 102%;
      height: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    .coke-text-header {
      margin-left: vw(24);
      margin-top: vw(28);
      font-size: vw(28);
    }
    .coke-text-body {
      margin-left: vw(24);
      margin-top: vw(8);
    }
    .coke-button-primary {
      margin-left: vw(24);
      margin-top: vw(24);
      width: vw(205);
      height: vw(47);
    }
  }
</style>