<script setup>


// grab state
const store = useStore();import { inject } from 'vue'
import UncopyableImage from "@/components/UncopyableImage";
import audioControl from "@/js/audioControl";
import loadSequence from "@/js/loadSequence";
import { ref, computed } from "vue";
import {useStore} from "vuex";
const translate = inject('translate')
const loading = ref(false);
const loaded = ref(false);
const isPermissionFinished = computed(() => store.getters.isPermissionFinished);


async function doPermissions() {
  audioControl.restartAudio("positive");

  setTimeout(async function (){
    if (!loading.value) {
      store.dispatch("legalFooterActive", false);
      loading.value = true;
      store.commit("reset");
      store.commit("loadStarted");

      await loadSequence.asyncPermissions();

      store.commit("isPermissionFinished", true);

      await loadSequence.asyncLoad();

      setTimeout(() => {
        audioControl.init();
      }, 300);

      loading.value = false;
      loaded.value = true;
    }
  }, 200)
}

function finishLoading() {
  store.commit("loadComplete");
}

</script>

<template>
  <div class="loader-container absolute-fill">
    <img src="../../public/assets/imgs/8th-wall-logo.png" class="logo-8th-wall">

    <div class = "permissions-request-container" v-if="!isPermissionFinished">

      <img src="../../public/assets/imgs/icon-camera.png" class="icon">
      <div class = "text">
        This experience uses your<br>motion sensors & camera
      </div>
      <div class = "permissions-button coke-button-primary" @click = "doPermissions" id="ar_permissions_continue">
        <div>Continue</div>
      </div>
      <div class = "permissions-button coke-button-secondary" id="ar_permissions_cancel">
        <div>Cancel</div>
      </div>
    </div>

    <div class="loading-middle-container" v-if="isPermissionFinished">
      <img src="../../public/assets/imgs/loader.png" class="loader-animation">
      <div class="loader-text">Loading...</div>
    </div>
    <!--
        <InstructionsWrapper v-if = "isPermissionFinished" @close = "finishLoading" :loaded = "loaded">
    </InstructionsWrapper>
    -->



    <!-- <PermissionPopup /> -->
  </div>
</template>
