<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { inject } from 'vue'
import audioControl from "@/js/audioControl";
const translate = inject('translate')
const vw = inject('vw');

// grab store
const store = useStore();

const isDesktop = ref(false);
const hasOpened = ref(false);

// Computed props
const isActive = computed(() => store.getters.getLegalFooterActive);
const isRotated = computed(() => store.getters.isRotated);
const isMobile = computed(() => store.getters.getIsMobile);

const toggleActive = (val) => {
  hasOpened.value = true;
  store.commit(
    "setLegalFooterActive",
    val !== undefined ? val : !isActive.value
  );
  if (isActive.value)
    audioControl.restartAudio("positive");
  else
    audioControl.restartAudio("negative");
};
const getLink = (textKey, linkKey) => {
  var text = translate(textKey);
  var link = translate(linkKey);

  return `<a href='${link}' target="_blank">${text}</a>`;
};

// Lifecycle functions
onMounted(() => {
  if (!isMobile.value) isDesktop.value = true;
});
</script>

<template>
  <div class="popup-wrapper">
    <div class="lockup-wrapper" v-if="!isRotated" />
    <div
      v-if="!isRotated"
      class="popup-legal-footer"
      :class="{
        isActive: isActive || isDesktop,
        isDesktop: isDesktop
      }"
    >
      <div
        class="legal-footer-main"
        :class="{ isActive: isActive || isDesktop }"
      >
        <div
          class="mainContentArea"
          :class="{ isActive: isActive || isDesktop }"
        >
          <div class="hiderArea" @click="toggleActive()" v-if="!isDesktop">
            <div class="hiderAreacontent">
              <span class="barText" v-html="translate('legal-footer-1')" />
              <div class="btnClose-container">
                <img v-if="isActive" class="btnClose" src="../../../public/assets/imgs/footer-close-expand-btn.png">
                <img v-if="!isActive" class="btnOpen" src="../../../public/assets/imgs/footer-close-expand-btn.png">
              </div>
            </div>
          </div>
          <div class="mainContent">
            <div class="mainContentWrapper">
              <div class="logo-area">
                <img src="../../../public/assets/imgs/logo-coke.png" class="logo1">
                <img src="../../../public/assets/imgs/knotts-berry-logo.png" class="logo2">
              </div>
              <div class="line1" v-html="translate('legal-footer-2')" >

              </div>

              <!-- OneTrust Cookies Settings button start -->
              <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
              <!-- OneTrust Cookies Settings button end -->

              <div class="linkOutArea policies">
                <span
                    class="linkOutText-correction"
                    v-html="getLink('terms-conditions-text', 'terms-conditions-url')"
                />
                <img src="../../../public/assets/imgs/arrow.png" class="arrow">
                <span
                  class="linkOutText"
                  v-html="getLink('privacy-policy-text', 'privacy-policy-url')"
                />
                <img src="../../../public/assets/imgs/arrow.png" class="arrow">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../sass/Settings";
.popup-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  .lockup-wrapper {
    position: relative;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .popup-legal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 11;
    pointer-events: all;
    transform: translateY(88%);
    transition: transform 0.25s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.loaded {
      transform: translateY(90%);
    }
    &.isActive {
      transform: translateY(0);
    }
    &.appFailed {
      z-index: 11;
      .legal-footer-persistent {
        background-color: white;
      }
      .legal-footer-main {
        background-color: white;
        .mainContentArea {
          background-color: white;
          .mainContent {
            background-color: white;
          }
        }
      }
    }
    .landing-content-sub-second {
      position: relative;
      bottom: 10px;
    }
    .legal-footer-main {
      transition: all 0.25s ease-in-out 0s;
      height: 100%;

      width: 100%;
      background-color: white;

      &.isActive {
        height: 100%;
      }

      .mainContentArea {
        width: 100%;
        top: 0;
        height: 100%;
        #teconsent {
          margin-right: 5px;
          background: transparent !important;
        }
        .hiderArea {
          position: relative;
          height: vw($legal-footer-closed-height);
          width: 100%;
          pointer-events: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #d00013;
          .hiderAreacontent {
            width: 96%;
            display: flex;
            .btnClose-container {
              display: flex;
              align-items: center;
              .btnOpen,
              .btnClose {
                height: vw(5);
                width: vw(10);
              }
            }
          }
        }

        .mainContent {
          position: relative;
          width: 100%;
          height: 100%;
          background-color: #000;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .mainContentWrapper {
            width: 93%;
            .logo-area {
              display: flex;
              margin-top: vw(31);
              margin-left: vw(24);
              margin-bottom: vw(20);
              .logo1 {
                width: vw(88);
                height: vw(28);
                margin-right: vw(28);
              }
              .logo2 {
                width: vw(63);
                height: vw(31);
              }
            }
            .line1 {
              font-family: TCCC-UnityText;
              font-size: vw(10);
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.2;
              letter-spacing: vw(0.1);
              text-align: left;
              color: #fff;
              margin-left: vw(24);
              margin-bottom: vw(12);
            }
          }

          .ot-sdk-show-settings {
            color: #FFFFFF !important;
            border: none !important;
            background: none;
            padding: 0 !important;
            display: block;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            font-family: TCCC-UnityText !important;
            font-size: vw(10) !important;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2 !important;
            letter-spacing: vw(0.1) !important;
            text-align: left;
            margin-bottom: vw(10);
            margin-left: vw(54);

          }
          .ot-sdk-show-settings:active {
            color: #FFFFFF !important;
            border: none !important;
            background-color: transparent !important;
          }
          .ot-sdk-show-settings:hover {
            color: #FFFFFF !important;
            border: none !important;
            background-color: transparent !important;
          }
        }
      }
    }
    &,
    * {
      box-sizing: border-box;
    }
    .legal-footer-persistent {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      height: vw(88);
      background-color: #fff;

    }
    .barText {
      padding-bottom: 0.5px;
      font-family: TCCC-UnityText;
      font-size: vw(10);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: vw(0.5);
      text-align: right;
      color: #fff;
      margin-right: 5px;
      box-sizing: inherit;
      width: 100%;
    }
    .btnOpen,
    .btnClose {
      transition: none !important;
      pointer-events: auto;
      // position: relative;
      // bottom: 2.7px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;
    }
    .btnClose {
      transform: rotate(180deg);
    }
  }
}
.linkOutArea.policies {
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding-top: 0 !important;

  margin-left: vw(54);
  margin-bottom: vw(12);
  font-family: TCCC-UnityText;
  font-size: vw(10);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: vw(0.1);
  text-align: left;
  color: #fff;
  .arrow {
    width: vw(14);
    height: vw(8);
    margin-left: vw(10);
  }
  .arrow {
    &:first-of-type {
      margin-right: 30px;
    }
  }
}
.popup-legal-footer.isDesktop {
  .legal-footer-main {
    .mainContentArea {
      .mainContent {
        .mainContentWrapper {
          width: 93%;
          .logo-area {
            margin-top: vh(31, $design-height-desktop);
            margin-left: vh(24, $design-height-desktop);
            margin-bottom: vh(20, $design-height-desktop);
            .logo1 {
              width: vh(88, $design-height-desktop);
              height: vh(28, $design-height-desktop);
              margin-right: vh(28, $design-height-desktop);
            }
            .logo2 {
              width: vh(63, $design-height-desktop);
              height: vh(31, $design-height-desktop);
            }
          }
          .line1 {
            font-size: vh(10, $design-height-desktop);
            letter-spacing: vh(0.1, $design-height-desktop);
            margin-left: vh(24, $design-height-desktop);
            margin-bottom: vh(12, $design-height-desktop);
          }
        }
        .ot-sdk-show-settings {
          font-size: vh(10, $design-height-desktop) !important;
          letter-spacing: vh(0.1, $design-height-desktop) !important;
          margin-left: vh(24, $design-height-desktop) !important;
          margin-bottom: vh(12, $design-height-desktop) !important;

        }
      }
    }
  }
  .linkOutArea.policies {
    margin-left: vh(24, $design-height-desktop);
    margin-bottom: vh(12, $design-height-desktop);
    font-size: vh(10, $design-height-desktop);
    letter-spacing: vh(0.1, $design-height-desktop);
    .arrow {
      width: vh(14, $design-height-desktop);
      height: vh(8, $design-height-desktop);
      margin-left: vh(10, $design-height-desktop);
    }
    .arrow {
      &:first-of-type {
        margin-right: 30px;
      }
    }
  }
}
</style>
