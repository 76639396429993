<script setup>
import ArWrapper from "./ArWrapper.vue";
import LoadingWrapper from "./LoadingWrapper.vue";
import Fail from "./fail_modules/FailWrapper.vue"
import LegalFooter from "../components/modules/PopupLegalFooter.vue"
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import ScannerWrapper from "@/views/ScannerWrapper.vue";
import InstructionsPopup from "@/views/InstructionsPopup.vue";
import Shutter from "@/components/modules/ShutterButton.vue";
import PhotoDisplay from "@/components/PhotoDisplay.vue";
import carouselData from "@/data/carouselData.json";
import AR from "@/js/playcanvasAR";
import audioControl from "@/js/audioControl";
import photocapture from "@/js/photoCapture";

const store = useStore();
const loaded = computed(() => store.getters.getLoaded);
const scanned = computed(() => store.getters.getScanned);
const isFlashing = computed(() => store.getters.getFlashing);

const getAppFailed = computed(() => store.getters.getAppFailed);
const getInstructionsClosed = computed(() => store.getters.getInstructionsClosed);

// grab state
const mediaURL = ref("");
const blobURL = ref("");
const mediaIsVideo = ref(false);
const mediaLoading = ref(carouselData.pages);

const takePhoto = () => {
  audioControl.restartAudio("camerashutter");
  store.commit("setFlashing", true);
  setTimeout(function () {
    store.commit("setFlashing", false);
  }, 200)
  mediaLoading.value = true;
  //analytics.dataLayerEvent("Link Click", "ar_camera_photo", "Read", "Engagement");
  if (store.getters.getPhotoOpen) {
    store.commit("setPhotoOpen", false);
  } else {
    photocapture.capture((url, blob) => {
      if (url != null) {
        mediaURL.value = url;
        blobURL.value = blob;
        mediaIsVideo.value = false;
        store.commit("setPhotoOpen", true);
        mediaLoading.value = false; //Theres no fail safe if photocapture fails, might want to fix that
      }
    });
  }
};

const startVideo = () => {
  console.log("starting video");
  AR.forceRenderFrame();
  AR.app.fire("start-video-recording", { onFinish: onVideoProcessed });
};
const stopVideo = () => {
  console.log("stopping video");
  mediaLoading.value = true;
  AR.app.fire("stop-video-recording");
};
const onVideoProcessed = ({ videoBlob }) => {
  console.log(videoBlob);
  mediaLoading.value = false;
  let file = new File([videoBlob], "recording.mp4", { type: "video/mp4" });
  mediaURL.value = URL.createObjectURL(file);
  blobURL.value = videoBlob;
  mediaIsVideo.value = true;
  store.commit("setPhotoOpen", true);
  //analytics.dataLayerEvent("Link Click", "ar_camera_video", "Read", "Engagement");
};

const isPhotoDisplayOpen = computed(() => store.getters.getPhotoOpen);
const getIsIOS = computed(() => store.getters.getIsIOS);
</script>

<template>
  <div
      style = "z-index: 2; pointer-events: none"
      class = "absolute-fill main-wrapper"
  >
    <transition name = "fade">
      <div class="ar-container absolute-fill" id="ar"  v-if = "loaded && getInstructionsClosed && scanned && !isPhotoDisplayOpen">
        <!-- All bottom anchored buttons -->
        <transition name="fade">
          <div class="bottom-icons">
            <!-- Shutter Button -->
            <Shutter
                @take-photo="takePhoto"
                @start-video="startVideo"
                @stop-video="stopVideo"
                :allow-video="true"
                :enabled="!mediaLoading.value"
            />
          </div>
        </transition>
      </div>
    </transition>


    <ScannerWrapper v-if="loaded && getInstructionsClosed && !scanned && !isPhotoDisplayOpen"></ScannerWrapper>
    <transition name = "fade">
      <InstructionsPopup v-if="loaded && !getInstructionsClosed && !scanned"></InstructionsPopup>
    </transition>
    <transition name = "fade">
      <LoadingWrapper v-if = "!loaded && !getAppFailed" />
    </transition>

    <PhotoDisplay
        :mediaURL="mediaURL"
        :blobURL="blobURL"
        :isVideo="mediaIsVideo"
        :has-download="!getIsIOS"
        v-if="isPhotoDisplayOpen"
    />

    <transition name = "camera-flash">
      <div v-if = "isFlashing" class = "camera-flash" />
    </transition>
    <transition name = "fade">
      <Fail v-cloak v-if = "getAppFailed" />
    </transition>
    <LegalFooter />
  </div>
</template>
