<script setup>
import { computed } from "vue";

import FailDesktop from "./FailDesktopGeneric";
import FailRotated from "./FailRotated";
import FailGeneric from "./FailGeneric";
import FailList from "./FailList";

import { useStore } from "vuex";

import { inject } from 'vue'
const translate = inject('translate')
const store = useStore();
/*
const motionRefresh = () => {
  store.commit("setMotionPermissionsCancelled", false);
  store.commit("setAppFailed", false);
};
 */

const cameraAutoRejected = computed(
  () => store.getters.getWasCameraAutoRejected
);
const isIOS = computed(() => store.getters.getIsIOS);
const hasGyro = computed(() => store.getters.getGyro);
const isAndroid = computed(() => store.getters.getIsAndroid);

const camPermissionsDenied = computed(
  () => store.getters.getCameraPermissionsFailed
);
const isChrome = computed(() => store.getters.getIsChrome);
const isRotated = computed(() => store.getters.getIsRotated);

//const upgradeIOS = computed(() => store.getters.getUpgradeIOS);
const override = computed(() => store.getters.getErrorScreenOverride);
console.log("override");
console.log(override.value);
//const legalFooterActive = computed(() => store.getters.getLegalFooterActive);
const supportedBrowser = computed(() => store.getters.getIsBrowserSupported);
const iOSMajorVersion = computed(() => store.getters.getIOSMajorVersion);
const iOSVersionCutoff = computed(() => store.getters.getIOSVersionCutoff);
const overrideDevice = computed(() => {
  if (override.value.substring(0, 3) === "ios") return "ios";
  else if (override.value.substring(0, 7) === "android") return "android";
  else return "";
});
const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
console.log("getIsDesktop");
console.log(getIsDesktop);
</script>

<template>
  <div class="fail-container">
    <div
      class="view-window"
      :class="{
        'is-desktop': getIsDesktop,
        'is-mobile-rotated': !getIsDesktop && isRotated,
      }"
    >
      <div
        class="error-panel"
        :class="{
          'is-desktop': getIsDesktop,
          'is-mobile-rotated': !getIsDesktop && isRotated,
        }"
      >
        <!-- Header Image -->
        <!--
        <UncopyableImage
          class="image image-header"
          src="../www/assets/imgs/trigger-logo.png"
          :class="{
            'is-desktop': getIsDesktop,
            'is-mobile-rotated': !getIsDesktop && isRotated,
          }"
          :width="getIsDesktop ? 470 : 247"
          :height="getIsDesktop ? 120 : 63"
          :use-desktop-proportion="getIsDesktop"
          :mobile-landscape="!getIsDesktop && isRotated"
          :use-width-scaling="!getIsDesktop && !isRotated"
          :scale-to-viewport="!getIsDesktop"
        />
        -->

        <!-- Rotation Error -->
        <FailRotated
                class="module"
                v-if="isRotated"
                :title="translate('error-screens-rotate-device')"
        />

        <!-- Desktop Error -->
        <FailDesktop class="module" v-else-if="getIsDesktop" />

        <!-- iOS Specific Errors -->
        <div v-else-if="(isIOS && overrideDevice !== 'android') || overrideDevice === 'ios'">
          <!-- Incompatible Browser -->
          <FailGeneric
                  class="module"
                  v-if="!supportedBrowser || override === 'iosIncompatibleBrowser'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-android-error-header', class: 'header'},
                    {html: '<b>'+translate('web-ar-url')+'</b>', class: 'link'},
                    {text: 'error-screens-android-incompatible-browser-body-2'}
            ]"
          />

          <!-- Motion Permissions Denied (General) -->
          <FailGeneric
                  class="module"
                  v-else-if="(!hasGyro && iOSMajorVersion !== 12) || override === 'iosMotionDenied13'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'motion-and-camera-denied-header', class: 'header'},
                    {text: 'motion-and-camera-denied-body-1'}
            ]"
          />

          <!-- Motion Permissions Denied (iOS 12) -->
          <FailList
                  class="module"
                  v-else-if="(!hasGyro && iOSMajorVersion === 12) || override === 'iosMotionDenied122'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :title="translate('error-screens-motion-sensor-request-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-ios-sensor-request-p1'),
                imageSource: '../www/assets/imgs/ios-settings-icon.png',
                imageWidth: 20,
                imageHeight: 20
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p2'),
                imageSource: '../www/assets/imgs/safari-icon.png',
                imageWidth: 20,
                imageHeight: 20
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p3'),
              },
              {
                itemText: translate('error-screens-ios-sensor-request-p4'),
                imageSource: '../www/assets/imgs/error-screen-reload-icon.png',
                imageWidth: 20,
                imageHeight: 21
              },
            ]"
          />

          <!-- Camera Auto Denied -->
          <FailList
                  class="module"
                  v-else-if="cameraAutoRejected || override === 'cameraAutoReject'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :title="translate('error-screens-cam-setup-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-cam-setup-p1'),
              },
              {
                itemText: translate('error-screens-cam-setup-p2'),
              },
              {
                itemText: translate('error-screens-cam-setup-p3'),
              },
              {
                itemText: translate('error-screens-cam-setup-p4'),
              },
            ]"
          />
          <FailGeneric
              class="module"
              v-else-if="camPermissionsDenied || override === 'iosCameraRejected'"
              :is-desktop="getIsDesktop"
              :is-mobile-rotated="!getIsDesktop && isRotated"
              alternate-image="./assets/imgs/icon-refresh.png"
              :info="[
                    {text: 'error-screens-ios-cam-denied', class: 'header'}
            ]"
          />

          <!-- Upgrade OS (Generic) -->
          <FailGeneric
                  class="module"
                  v-else-if="iOSMajorVersion < iOSVersionCutoff || override === 'iosUpgrade'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-ios-incompatible-version-header', class: 'header'},
                    {text: 'error-screens-ios-incompatible-version-body'}
            ]"
          />

          <!-- Generic Error -->
          <FailGeneric
                  class="module"
                  v-else
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-general-error', class: 'header'},
                    {text: 'error-screens-general-error-body'}
            ]"
          />
        </div>
        <!-- Android Specific Errors -->
        <div
                v-else-if="(isAndroid && overrideDevice !== 'ios') || overrideDevice === 'android'"
        >
          <!-- Camera Denied (Generic) -->
          <FailList
                  class="module"
                  v-if="(camPermissionsDenied && !isChrome) || override === 'androidCameraDeniedSamsung'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :title="translate('error-screens-android-camera-req-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-samsung-camera-req-p1'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p2'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p3'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p4'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p5'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p6'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p7'),
              },
            ]"
          />

          <!-- Camera Denied (Chrome) -->
          <FailList
                  class="module"
                  v-else-if="(camPermissionsDenied && isChrome) || override === 'androidCameraDenied'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :title="translate('error-screens-android-camera-req-header')"
                  :list-items="[
              {
                itemText: translate('error-screens-samsung-camera-req-p1'),
              },
              {
                itemText: translate('error-screens-samsung-camera-req-p2'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p3'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p4'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p5'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p6'),
              },
              {
                itemText: translate('error-screens-android-camera-req-p7'),
              },
            ]"
          />

          <!-- Incompatible Browser -->
          <FailGeneric
                  class="module"
                  v-else-if="!supportedBrowser || override === 'androidIncompatibleBrowser'"
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-android-error-header', class: 'header'},
                    {html: '<b>'+translate('web-ar-url')+'</b>', class: 'link'},
                    {text: 'error-screens-android-incompatible-browser-body-2'}
            ]"
          />

          <!-- Generic Error -->
          <FailGeneric
                  class="module"
                  v-else
                  :is-desktop="getIsDesktop"
                  :is-mobile-rotated="!getIsDesktop && isRotated"
                  :info="[
                    {text: 'error-screens-general-error', class: 'header'},
                    {text: 'error-screens-general-error-body'}
            ]"
          />
        </div>
      </div>
    </div>
  </div>
</template>
