///////////////////////////////////////////////////////////////////////////
/*
Name: 			compatibility.js

Description: 	Checks whether the device / browser supports AR.

Usage:			Run General Check
                    - import compatibility from "./compatibility.js";
                    - await compatibility.checkDevice();
                    - This will automatically set the proper error variables in the store to active the corresponding error screen on Fai.vue

                Change Android Whitelist
                    - If the check finds any strings in the User Agent of browser that don't match the androidWhitelistedBrowsers array, they are assumed to be incompatible.
                    - To add a browser to the white, console.log their User Agent using getUA and observe end which is a set of strings describing the browser.
                    - Note that you will see generic ones like "Mozilla" and "Chrome" even in non-Chrome browsers because most browsers are based on other ones.
                    - Find the string that is unique to the browser itself and add it to the whitelist.

*/
///////////////////////////////////////////////////////////////////////////
import { store } from "../main";
import {
  getUA,
  isAndroid,
  isChrome,
  isIOS,
  isMobile,
  isMobileSafari,
  isTablet,
} from "mobile-device-detect";

const iosMultiBrowserVersion = 14;

var iosMultiBrowserSupported = false;

const androidWhitelistedBrowsers = [
  "Mozilla",
  "Firefox",
  "Gecko",
  "AppleWebKit",
  "Chrome",
  "SamsungBrowser",
  "Safari",
];
//NOTE: IOS whitelisted Browsers have only ever been Safari.

const compatibility = {
  async checkDevice() {
    store.commit("setIsChrome", isChrome);
    store.commit("setIsAndroid", isAndroid);
    store.commit("setIsMobileSafari", isMobileSafari);
    store.commit("setIsMobile", isMobile);
    store.commit("setIsIOS", isIOS);
    store.commit("setIsTablet", isTablet);
    store.commit("setUA", getUA);

    if (isIOS) {
      var version = getIOSVersion(getUA);
      if (version != "") {
        var versionSplit = version.split("_");

        try {
          var majorVersion = parseInt(versionSplit[0], 10);
          var minorVersion = parseInt(versionSplit[1], 10);
          store.commit("setIOSMajorVersion", majorVersion);
          store.commit("setIOSMinorVersion", minorVersion);
          if (majorVersion < store.getters.getIOSVersionCutoff) {
            store.commit("setAppFailed", true);
            store.commit("setUpgradeIOS", true);
          }
          iosMultiBrowserSupported = majorVersion >= iosMultiBrowserVersion;
        } catch (e) {
          console.log("Could not parse version number.");
        }
      }
    }

    if (isAndroid && !store.getters.getAppFailed) {
      var unsupported = isAndroidBrowserUnsupported(getUA);
      store.commit("setAndroidUnsupported", unsupported);
      store.commit("setIsBrowserSupported", !unsupported);
      store.commit("setAppFailed", unsupported);
    }

    if (getUA.match(/OS 11_2/gi)) {
      store.commit("setUpgradeIOS", true);
      store.commit("setAppFailed", true);
    }

    // Check if IphoneX by using pixel ratios
    var ratio = window.devicePixelRatio || 1;
    var screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    if ((screen.width / screen.height).toFixed(2) == (1125 / 2436).toFixed(2)) {
      //console.log("Is iphone X");
      store.commit("setIsIphoneX", true);
    }

    if (!isTablet && !isMobile)
      //fail if desktop
      store.commit("setAppFailed", true);

    //iOS Browser support check covers non-8th wall
    if (isIOS) {
      if (iosMultiBrowserSupported || isMobileSafari)
        store.commit("setIsBrowserSupported", true);
      else store.commit("setAppFailed", true);
    }
    //Android support already covered

    //Browser support check, only works if we're using 8th Wall
    window.addEventListener(
      "xrloaded",
      function () {
        var xrDevice = XR8.XrDevice;
        if (!xrDevice.isDeviceBrowserCompatible()) {
          store.commit("setIsBrowserSupported", false);
          store.commit("setAppFailed", true);
        } else {
          store.commit("setIsBrowserSupported", true);
          store.commit("setAppFailed", false);
        }
      }.bind(this)
    );
  },
};

const isAndroidBrowserUnsupported = (ua) => {
  //Split the UA into pieces to analyze each part.
  var split = ua.split(" ");
  for (var i = 0; i < split.length; i++) {
    var entry = split[i];
    var slash = entry.indexOf("/");
    if (slash != -1) {
      var browserName = entry.substring(0, slash);
      //If the UA contains a string outside the whitelist, its unsupported
      if (!androidWhitelistedBrowsers.includes(browserName)) return true;
    }
  }
  return false;
};
const getIOSVersion = (ua) => {
  var os = "";
  var start = ua.indexOf("OS");
  var sub = ua.substring(start, ua.length - 1);
  var split = sub.split(" ");

  // store.commit("setDebugMessage", split);

  if (split.length > 1) os = split[1];
  return os;
};

export default compatibility;
